$(document).ready(function() {

	// Sliders

	$('.offer__slider').slick({
		dots: true,
		prevArrow: '<button type="button" class="offer__arrow offer__arrow_left"><i class="fas fa-angle-left"></i></button>',
		nextArrow: '<button type="button" class="offer__arrow offer__arrow_right"><i class="fas fa-angle-right"></i></button>',
		// autoplay: true,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					arrows: false
				}
			}
		]
	});

	$('.special__slider').slick({
	  infinite: true,
	  slidesToShow: 2,
	  slidesToScroll: 1,
	  // autoplay: true,
	  arrows: false,
	  responsive: [
	    {
	      breakpoint: 992,
	      settings: {
	        slidesToShow: 1
	      }
	    }
	  ]
	});

	$('.product__slider_right').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.product__slider_left'
	});
	$('.product__slider_left').slick({
		prevArrow: '<button type="button" class="product-arrow product-prev"><img src="img/arrow_up.png" alt=""></button>',
		nextArrow: '<button type="button" class="product-arrow product-next"><img src="img/arrow_up.png" alt=""></button>',
		slidesToShow: 3,
		slidesToScroll: 1,
		vertical: true,
		asNavFor: '.product__slider_right',
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					vertical: false
				}
			}
		]
	});

	$('.with').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: '<button type="button" class="with__angle with__angle_left"><img src="img/angle_left.png" alt=""></button>',
		nextArrow: '<button type="button" class="with__angle with__angle_right"><img src="img/angle_left.png" alt=""></button>',
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 2,
					arrows: false
				}
			}
		]
	});

	// Masonry

	if ($(window).width() > 1199) {
		if ($('div').is('.articles__wrap')) {
			$('.articles__wrap').masonry({
			  // options
			  itemSelector: '.article',
			  columnWidth: '.article',
			  gutter: 40
			});
		}
	} else if ($(window).width() > 991) {
		if ($('div').is('.articles__wrap')) {
			$('.articles__wrap').masonry({
			  // options
			  itemSelector: '.article',
			  columnWidth: 10,
			  gutter: 40
			});
		}
	}

	// Grid

	$('.recommend__wrap').each(function() {
		if ($(this).children('.recommend__block').length < 3) {
			$(this).css({'grid-template-rows': 'repeat(1, 260px)'});
		}
	});

	// Burger

	$('.burger').click(function(e) {
		e.preventDefault();
	    $('.menu__list').slideToggle(500).css({'display' : 'flex'});
	    $(this).toggleClass('burger_active');
	});

	$('.navigation__btn').click(function(e) {
		e.preventDefault();
	    $('.navigation__list').slideToggle(500);
	    $(this).toggleClass('navigation__btn_active');
	});

});